<template>
    <div class="express">
        <div class="express-box">
            <div class="img" @click="getBarCode">
                <img src="https://khome2.tuzuu.com/vita/express2.png"/>
                <span>点击扫描条形码</span>
            </div>
            <div class="input-list">
                <van-field v-model="barcode" label="条码号" placeholder="点击扫描一扫或手动输入">
                    <template #right-icon>
                        <van-icon v-if="barcode" name="clear" color="#c4c4c4" size="24" @click="clearBarCode"/>
                    </template>
                </van-field>

                <div class="address ww" v-if="!state">
                    <div style="margin-bottom: 15px !important ;font-size: 15px;color: #292C2F">
                        快递将于({{ form.time }})上门，请耐心等候
                    </div>
                    <div class="flex-between">
                        <span>
                            {{ form.username }}
                            <span style="margin-left: 15px">{{ data.sex }}</span>
                        </span>
                        <span style="color: #9E3DF6" @click="show = true">修改</span>
                    </div>
                    <div>
                        {{ form.phone.slice(0, 3) }}
                        {{ form.phone.slice(3, 7) }}
                        {{ form.phone.slice(7, 11) }}
                    </div>
                    <div>{{ form.address | strs }} {{ form.detailRess }}</div>
                </div>
                <van-field v-model="courierNumber" label="单号" placeholder="请输入快递单号" v-if="state">
                    <template #button>
                        <van-button size="small" color="#9E3DF6" @click="openGoHome">免费上门取件</van-button>
                    </template>
                </van-field>
            </div>
        </div>
        <div class="tag">
            我们将为您创建私人检测报告，请核对以下信息~
        </div>
        <div class="express-box" id="bottom">
            <div class="title flex-between">
                <span>检测人信息</span>
                <span @click="showKeFu=true">信息有误？请联系专属营养顾问</span>
            </div>
            <div class="address">
                <div v-if="data.consignee_name">
                    {{ data.consignee_name }}
                    <span style="margin-left: 15px">{{ data.sex }}</span>
                </div>
                <div v-if="data.consignee_name">
                    {{ data.consignee_tel.slice(0, 3) }} {{
                        data.consignee_tel.slice(3, 7)
                    }} {{ data.consignee_tel.slice(7, 11) }}
                </div>
                <div v-if="data.consignee_name">{{ data.id_no }}</div>

                <!--空-->
                <div class="flex-center" style="height: 70px" v-if="!data.consignee_name">
                    <div class="flex-center" style="align-items: end;">
                        <img src="https://khome2.tuzuu.com/vita/icon_10.png"
                             style="width: 16px;margin-right: 5px;transform: translateY(4px)">
                        <div style="font-size: 14px;color: #666666;line-height: 23px">
                            <p>很抱歉没有您的订单信息</p>
                            <p>请使用下单人微信扫码～</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="footer flex-column-center">
            <van-checkbox v-model="checked" shape="square" checked-color="#9E3DF6">
               <span @click="openPopup">
                     请阅读并<span style="color: #9E3DF6">同意《知情同意》</span>
                </span>

            </van-checkbox>
            <div class="btn" @click="expressSubmit">确认</div>
        </div>

        <!--地址-->
        <van-popup v-model="showArea" position="bottom">
            <van-area
                    :area-list="areaList"
                    @confirm="onConfirm"
                    @cancel="showArea = false"/>
        </van-popup>

        <!--时间-->
        <van-popup v-model="showTime" position="bottom" closeable>
            <div class="time-box">
                <div class="title">
                    确认顺丰上门取件地址
                </div>
                <van-tree-select
                        :items="items"
                        :active-id.sync="activeId"
                        :main-active-index.sync="activeIndex"
                        @click-item="selectTime"/>
            </div>
        </van-popup>

        <!--客服-->
        <van-popup v-model="showKeFu" position="bottom" closeable>
            <div class="time-box">
                <div class="title" style="font-weight: 700">
                    联系健康顾问
                </div>
                <div style="color: #3F3F3F">如果您对检测信息有疑问，可以联系健康顾问为您处理～</div>
                <div style="font-size: 16px;margin-top: 50px;margin-bottom: 27px;line-height: 26px">
                    <p style="font-weight: 700">{{ kefu.name }}</p>
                    <p>请长按二维码和我联系吧</p>
                </div>
                <van-image :src="kefu.qrcode" width="150px" height="150px" style="margin-bottom: 43px"/>
            </div>
        </van-popup>

        <!--协议-->
        <van-popup v-model="showPopup" position="bottom" :style="{ height: '60vh' }" round closeable>
            <div class="rule-con">
                <div class="rule-title">知情同意书</div>
                <div class="rule-info" v-html="agreement">

                </div>
            </div>
        </van-popup>

        <van-popup v-model="show" position="bottom" closeable>
            <div class="popup">
                <div class="title">
                    确认顺丰上门取件地址
                </div>
                <van-form>
                    <van-field
                            v-model="form.username"
                            name="寄件人姓名"
                            label="寄件人姓名"
                            placeholder="请输入寄件人姓名"/>
                    <van-field
                            v-model="form.phone"
                            name="寄件人手机号"
                            label="寄件人手机号"
                            placeholder="请输入寄件人手机号"/>
                    <van-field
                            readonly
                            clickable
                            name="area"
                            :value="form.address"
                            label="所在地区"
                            placeholder="请选择地区"
                            @click="showArea = true">
                        <template #right-icon>
                            <van-icon name="arrow" color="#c4c4c4" size="24"/>
                        </template>
                    </van-field>

                    <van-field
                            v-model="form.detailRess"
                            name="area"
                            placeholder="请输入详细地址，如xxx街道xxx号"
                            label="详细地址"/>
                    <van-field
                            readonly
                            clickable
                            name="area"
                            :value="form.time"
                            label="期望上门时间"
                            placeholder="请选择时间"
                            @click="showTime = true">
                        <template #right-icon>
                            <van-icon name="arrow" color="#c4c4c4" size="24"/>
                        </template>
                    </van-field>
                    <div class="hr" style="padding-bottom: 8px"></div>
                </van-form>
                <div class="btn" @click="addressSubmit">确认</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import wx from "weixin-js-sdk"
    import {areaList, validIdentity} from "@/until/index"
    import {str2} from "@/http/agreement"

    export default {
        name: "",
        created() {
            this.getData()
        },
        data() {
            return {
                areaList,
                barcode: '',
                courierNumber: '',
                show: false,
                showArea: false,
                showTime: false,
                showKeFu: false,
                showPopup: false,
                agreement: "",
                checked: true,
                activeId: 1,
                activeIndex: 0,
                state: true,//false是上门，true是自主
                kefu: {},

                data: {
                    consignee_tel: ''
                },
                form: {
                    username: '',
                    phone: '',
                    address: '',
                    detailRess: '',
                    time: ''
                },
                items: [
                    {
                        text: '今天',
                        children: []
                    },
                    {
                        text: '明天',
                        children: []
                    },
                    {
                        text: '后天',
                        children: []
                    }]
            }
        },
        methods: {
            validIdentity,
            //免费上门
            openGoHome() {
                if (this.courierNumber) {
                    this.$dialog.confirm({
                        message: '您已填写快递单号，使用免费上门取件系统将为您重新呼叫快递，是否继续？'
                    }).then(() => {
                        this.show = true
                    })
                } else {
                    this.show = true
                }
            },
            //清除条码号
            clearBarCode() {
                this.$dialog.confirm({
                    title: '是否确认删除该条码？',
                    confirmButtonText: '确定',
                    confirmButtonColor: '#6B00AF'

                }).then(() => {
                    this.barcode = ''
                }).catch(() => {
                    // on cancel
                });
            },
            getBarCode() {
                let toast = this.$toast.loading({
                    message: '打开扫码中',
                    forbidClick: true,
                })
                let _this = this
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        toast.clear()
                        if (res?.resultStr) {
                            if (res?.resultStr.includes(',')) {
                                _this.barcode = res?.resultStr.split(',')[1];
                                _this.$toast.success('扫码成功')
                            } else {
                                _this.$toast.fail('扫码失败')
                            }

                            console.log('扫一扫运行成功')
                            console.log(res)
                        } else {
                            _this.$toast.fail('扫码失败');
                        }
                    }
                })
            },
            onConfirm(values) {
                this.form.address = values
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');

                this.showArea = false;
            },
            selectTime(data) {
                let w
                switch (this.activeIndex) {
                    case 0:
                        w = '今天'
                        break
                    case 1:
                        w = '明天'
                        break
                    case 2:
                        w = '后天'
                        break
                }
                this.form.time = w + data.text
                this.showTime = false
            },
            initTimer() {
                let nowTime = new Date()
                let nowTimeHours = nowTime.getHours()
                let nowTimeHM = parseFloat((nowTimeHours + (nowTime.getMinutes()) / 60).toFixed(2))
                //填充数据
                let index = 0
                for (let i = 8; i < 19; i++) {
                    //获取时间范围

                    let j = i + 1 < 10 ? `0${i + 1}` : i + 1
                    i = i < 10 ? `0${i}` : i
                    //获取id
                    let obj = {
                        text: `${i}:00-${j}:00`,
                        id: index
                    }
                    this?.items[0]?.children?.push(obj)
                    this.items[1].children.push(obj)
                    this.items[2].children.push(obj)
                    index += 1
                }
                //判断今天数据
                if (nowTimeHM <= 18.3) {
                    this.items[0].children = this.items[0].children.filter((item) => {
                        let time = item.text.slice(6, 8)
                        if (parseFloat(time) > nowTimeHM) {
                            return item
                        }
                    })

                } else {
                    this.items.shift()
                }
            },
            addressSubmit() {
                let {username, phone, address, detailRess, time} = this.form
                let addressList = address.split("/")
                let patt2 = /^1[3|4|5|8]\d{9}$/
                if (!username) {
                    this.$toast('请输入寄件人姓名')
                } else if (!patt2.test(phone)) {
                    this.$toast('请输入正确的手机号')
                } else if (!address) {
                    this.$toast('请选择地区')
                } else if (!detailRess) {
                    this.$toast('请输入详细地址')
                } else if (!time) {
                    this.$toast('请选择时间')
                } else {
                    this.$request.post(
                        "check_address", {
                            country: '中国',
                            tel: phone,
                            province: addressList[0],
                            city: addressList[1],
                            county: addressList[2],
                            address: detailRess
                        }
                    ).then(res => {
                        if (res.code != 1) {
                            this.$dialog.alert({
                                message: '很抱歉，您的地址不支持一键呼叫。请您自行呼叫快递并在寄出后填写快递单号再次提交信息~',
                                confirmButtonText: '我知道了'
                            }).then(() => {
                                this.show = false
                                this.state = true
                            });
                        } else {
                            this.state = false
                            this.show = false
                        }
                    })
                }

            },
            expressSubmit() {
                this.formCheck(() => {
                    let {username, phone, address, detailRess, time} = this.form
                    let addressList = address.split("/")
                    //过滤事件
                    let str = time
                    let timer = ''
                    if (str.slice(0, 2) == '今天') {
                        let day = new Date()
                        day.setTime(day.getTime())
                        timer = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate() + ' ' + str.slice(8, 10) + ':00:00';
                    } else if (str.slice(0, 2) == '明天') {
                        let day = new Date()
                        day.setTime(day.getTime() + 24 * 60 * 60 * 1000)
                        timer = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate() + ' ' + str.slice(8, 10) + ':00:00';
                    } else if (str.slice(0, 2) == '后天') {
                        let day = new Date()
                        day.setTime(day.getTime() + 24 * 60 * 60 * 1000 * 2)
                        timer = day.getFullYear() + "-" + (day.getMonth() + 1) + "-" + day.getDate() + ' ' + str.slice(8, 10) + ':00:00';
                    }
                    this.$dialog.confirm({
                        title: '确认信息',
                        message: `为保障检测结果准确性，请确保条码号输入正确<br/>条码号:${this.barcode}`
                    }).then(() => {
                        if (this.state) {
                            this.$request.post(
                                "add_new_gene_box", {
                                    apply_codes: this.barcode,
                                    apply_person: this.data.consignee_name,
                                    gender: this.data.sex,
                                    idcard: this.data.id_no,
                                    mobile: this.data.consignee_tel,
                                    express_no: this.courierNumber,
                                    order_id: this.data.sn,
                                }
                            ).then(res => {
                                if (res.code != 1) {
                                    this.$toast(res.message)
                                } else {
                                    this.$router.push({
                                        path: '/my/expr_success'
                                    })
                                }

                            })
                        } else {
                            this.$request.post(
                                "one_click_call", {
                                    apply_codes: this.barcode,
                                    apply_person: this.data.consignee_name,
                                    gender: this.data.sex,
                                    idcard: this.data.id_no,
                                    mobile: this.data.consignee_tel,
                                    j_address: detailRess,
                                    j_province: addressList[0],
                                    j_city: addressList[1],
                                    j_county: addressList[2],
                                    j_contact: username,
                                    j_tel: phone,
                                    order_id: this.data.sn,
                                    send_start_time: timer
                                }
                            ).then(res2 => {
                                if (res2.code != 1) {
                                    this.$toast(res2.message)
                                } else {
                                    this.$router.push({
                                        path: '/my/expr_success'
                                    })
                                }
                            })
                        }
                    })
                }, this.state)
            },
            formCheck(we, w = true) {
                let patt = /^\d{12}$/
                if (!this.checked) {
                    this.$toast('请阅读并勾选同意《知情同意书》')
                } else if (!this.barcode) {
                    this.$toast('请扫一扫条码号')
                } else if (!patt.test(this.barcode)) {
                    this.$toast('请输入正确的条码号')
                } else if (!this.courierNumber && w) {

                    this.$toast('请填写快递单号或一键呼叫快递上门取件~')
                } else {
                    if (we) {
                        we()
                    }
                }
            },
            openPopup() {
                this.showPopup = true
                this.checked = !this.checked
            },
            getData() {
                this.agreement = str2
                this.initTimer()
                let id = this.$route.query.adviser_id
                this.$request.get(
                    "get_adviser_qrcode", {
                        adviser_id: id || 0
                    }
                ).then(res => {
                    if (res.code == 1) {
                        this.kefu = res.data
                    } else {
                        this.$toast(res.message)
                    }
                })
                //初始化数据
                this.$request.get(
                    "detector_info"
                ).then(res => {
                    if (res.code == 1) {
                        this.data = res.data
                        setTimeout(() => {
                            this.$nextTick(() => {
                                let bottom = document.querySelector("#bottom")
                                let obj = document.querySelector(".express")
                                let selfHeight = bottom.offsetHeight
                                let selfTop = bottom.offsetTop
                                let innerH = window.innerHeight
                                if(innerH - selfTop - selfHeight < 90){
                                    obj.style.paddingBottom = 120 +'Px'
                                }
                            })
                        },100)
                        if (res.data.has_record == 1) {
                            this.$router.push({
                                path: '/my/expr_success'
                            })
                        }
                    }
                })
            }

        },
        filters: {
            strs(val) {
                return val.replace('/', '').replace('/', '')
            }
        }
    }
</script>

<style scoped lang="less">
    /deep/ .van-icon {
        border-radius: 4px !important;
    }

    .ww {
        div {
            margin-bottom: 11px !important;

            &:last-of-type {
                margin-bottom: 0px !important;
            }
        }
    }

    .express {
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;
        padding: 0px 10px;
        padding-top: 15px;
        box-sizing: border-box;
        position: relative;
    }

    .express-box {
        width: 100%;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        padding: 0px 15px;
        box-sizing: border-box;

        .img {
            padding: 10px 19px 10px 19px;
            box-sizing: border-box;
            position: relative;

            span {
                position: absolute;
                font-size: 15px;
                color: #292C2F;
                left: 0;
                bottom: 15px;
                width: 100%;
                text-align: center;
            }
        }

        .input-list {
            /deep/ .van-button {
                border-radius: 5px;
            }

            /deep/ .van-cell {
                padding: 0px 0px;
                display: flex;
                align-items: center;
                height: 55px;

                &:after {
                    left: 0;
                    width: 100%;
                    border-bottom: 1px solid #979797;
                }
            }

            /deep/ .van-field__control {
                font-size: 15px;
            }

            /deep/ .van-cell__title {
                font-size: 15px;
                margin-right: 0px;
                color: #292C2F;
            }
        }

        .title {
            padding: 20px 0px 15px 0px;
            box-sizing: border-box;
            border-bottom: 1px solid #979797;

            span:nth-child(1) {
                font-size: 16px;
                font-weight: 700;
                color: #292C2F;
            }

            span:nth-child(2) {
                font-size: 15px;
                color: #9E3DF6;
                transform: translateY(1px);
            }
        }

        .address {
            font-size: 14px;
            color: #666666;
            padding: 15px 0px 20px 0px;

            div {
                margin-bottom: 15px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    .tag {
        font-size: 14px;
        color: #292C2F;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .popup {
        padding: 34px 22px 20px 22px;

        .title {
            font-size: 16px;
            color: #292C2F;
            text-align: center;
            margin-bottom: 30px;
        }

        .van-form {
            /deep/ .van-cell__title {
                font-size: 15px;
                margin-right: 0px;
                color: #3F3F3F;
            }

            /deep/ .van-field__control {
                text-align: right;
                font-size: 15px;
                //color: #292C2F;
            }

            /deep/ .van-cell {
                padding: 17px 0px;

                &:after {
                    left: 0;
                    width: 100%;
                    border-bottom: 1px solid #E7E7E7;
                }
            }
        }
    }

    .time-box {
        padding: 34px 0px 0px 0px;
        font-size: 14px;
        text-align: center;

        .title {
            font-size: 16px;
            color: #292C2F;
            text-align: center;
            margin-bottom: 30px;
        }

        .van-sidebar-item {
            font-weight: 700;
            font-size: 16px;
            color: #3F3F3F;
            padding: 28px 0px;
            text-align: center;
        }

        /deep/ .van-sidebar-item--select {
            color: #BF182D;

            &::before {
                display: none;
            }
        }

        /deep/ .van-tree-select__item {
            font-size: 15px;
            color: #3F3F3F;

            .van-icon-success::before {
                color: #ee0a24;
                font-size: 18px;
                transform: translateY(-2px);
            }
        }

        /deep/ .van-tree-select__item--active {
            font-weight: 700;
            color: #ee0a24;
        }
    }

    .btn {
        color: #9E3DF6;
        background-color: #9E3DF6;
        width: 80%;
        margin: 15px auto;
        margin-bottom: 0;
        border-radius: 20px;
        color: white;
        font-size: 16px;
        padding: 10px 0px;
        text-align: center;
    }

    .footer {
        position: absolute;
        bottom: 20px;
        width: 100%;
        left: 0;

        /deep/ .van-checkbox__label {
            font-size: 14px;
            color: #666666;
        }
    }

    .rule-con {
        padding: 20px 20px;
        box-sizing: border-box;
    }

    .rule-title {
        font-weight: 700;
        font-size: 16px;
        color: black;
        text-align: center;
        height: 40px;
    }

    .rule-info {
        font-size: 13px;
        color: black;
        line-height: 24px;
        height: calc(60vh - 80px);
        overflow: auto;
    }

</style>